import cx from 'classnames';

import ArrowLeftIcon from 'assets/icons/arrow-right.svg';
import { HOME_META_DESCRIPTION } from 'consts/seo';
import classes from 'styles/Home.module.css';
import Container from 'ui/components/Container';
import Layout from 'ui/components/Layout';
import Meta from 'ui/components/Meta';
import Typography from 'ui/components/Typography';

const CTA_LINKS = [
  { children: 'Belgium', href: 'https://www.vivino.com/merchants/34590' },
  { children: 'Denmark', href: 'https://www.vivino.com/merchants/32306' },
  { children: 'Germany', href: 'https://www.vivino.com/merchants/34543' },
  { children: 'Netherlands', href: 'https://www.vivino.com/merchants/34589' },
  { children: 'Sweden', href: 'https://www.vivino.com/merchants/32432' },
];

export default function Home() {
  const mobileImageRendered = (
    <img
      className={classes.amforaMobileApp}
      alt="Amfora mobile application"
      src="/images/phone.png"
    />
  );

  return (
    <>
      <Meta description={HOME_META_DESCRIPTION} image="/images/phone.png" />
      <Layout>
        <Container className={classes.layoutContainer}>
          <main className={classes.root}>
            <img className={classes.logo} src="/brand/logo.svg" alt="Brand logotype" />

            <div className={classes.mainContainer}>
              <div className={classes.leftContainer}>
                <div className={classes.textContainer}>
                  <Typography variant="h1" as="h1" fontWeight={700}>
                    Top-rated wines from
                    <br />
                    the best vineyards
                  </Typography>

                  <Typography as="p">
                    Amfora is a curated online wine shop, dedicated to top-rated wines only. Working
                    directly with carefully selected wineries, we specialise in 4+ rated wines in
                    popular styles from the best vineyards and regions. From classic brands to
                    hard-to-find artisanal gems, our specialty selection offers the highest ranked,
                    best quality wines at every price point.
                    <br />
                    <br />
                    All available on Vivino, with quick home delivery right to your doorstep.
                  </Typography>
                </div>

                <div className={classes.outerCtaContainer}>
                  <div className={classes.ctaContainer}>
                    <div className={classes.cta}>
                      <Typography fontWeight={700}>Shop on</Typography>
                      <img
                        className={classes.vivino}
                        src="/images/vivino.svg"
                        alt="Vivino logotype"
                      />
                    </div>

                    <div className={classes.ctaLinks}>
                      {CTA_LINKS.map(link => (
                        <a
                          target="_blank"
                          href={link.href}
                          className={cx(classes.ctaLink, classes.link)}
                          key={link.children}
                          rel="noreferrer"
                        >
                          <Typography>{link.children}</Typography>
                          <ArrowLeftIcon />
                        </a>
                      ))}
                    </div>
                  </div>

                  <div className={classes.ctaMobileContainer}>{mobileImageRendered}</div>
                </div>
              </div>
              <div className={classes.mobileContainer}>{mobileImageRendered}</div>
            </div>
          </main>

          <footer>
            <a className={cx(classes.contactLink, classes.link)} href="mailto:hello@amfora.com">
              <div className={classes.emailIcon}></div>

              <Typography>
                {/* The a after the @ is tight. */}
                hello<span style={{ letterSpacing: 1 }}>@</span>amfora.com
              </Typography>
            </a>
          </footer>
        </Container>
      </Layout>
    </>
  );
}
